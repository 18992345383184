<template>
	<b-modal
		id="change-address-modal"
		size="lg"
		header-class="mx-auto w-100 bg-dark text-white"
		centered
		no-close-on-backdrop
		no-close-on-esc
		hide-footer>
		<template v-slot:modal-header>
			<h5
				class="modal-title text-white">
				{{ translate('change_shipping_address') }}
			</h5>
			<button
				type="button"
				aria-label="Close"
				class="close text-white"
				@click="$bvModal.hide('change-address-modal'); newAddress = null;">
				×
			</button>
		</template>
		<h5>{{ translate('current_address') }}:</h5>
		<div
			style="padding: 10px 20px; background-color: #f3f3f3; border-radius: 4px; margin-bottom: 20px">
			<p class="mb-1">
				<b>{{ orderDetails.shipping.name }}</b>
			</p>
			<p
				v-if="orderDetails.shipping.telephone"
				class="mb-1">
				{{ orderDetails.shipping.telephone }}
			</p>
			<p
				v-if="orderDetails.recipient_id_number"
				class="mb-1">
				{{ orderDetails.recipient_id_number }}
			</p>
			<p class="mb-1 text-wrap">
				{{ orderDetails.shipping.line1 }}
			</p>
			<p class="mb-1 text-wrap">
				{{ orderDetails.shipping.line2 }}
			</p>
			<p class="mb-1">
				{{ orderDetails.shipping.line3 }}
			</p>
			<p class="mb-1">
				{{ orderDetails.shipping.line4 }}
			</p>
			<p class="mb-1">
				{{ orderDetails.shipping.line5 }}
			</p>
		</div>
		<hr>
		<h5>{{ translate('select_or_create_a_new_address') }}:</h5>
		<!-- We need to use the order country (not the user one) -->
		<shipping-address
			:user-id="userId"
			:is-user-associated="true"
			:country-to-show="orderDetails.country_code"
			:is-gift-order="!!orderDetails.is_gift_order"
			:submit-loading="submitLoading"
			:submit-disabled="!newAddress"
			:show-normal-addresses="!!orderDetails.can_change_to_shipping"
			:show-pickup-addresses="true"
			:can-change-to-shipping="orderDetails.can_change_to_shipping"
			@change="updateShippingAddress"
			@submit="submit" />
	</b-modal>
</template>

<script>
import ShippingAddress from '@/components/ChangeShippingAddressModal/ShippingAddress.vue';
import Order from '@/util/Order';
import { Addresses } from '@/translations';

export default {
	name: 'ChangeShippingAddressModal',
	messages: [Addresses],
	components: { ShippingAddress },
	props: {
		userId: {
			type: [String, Number],
			required: true,
		},
		orderId: {
			type: [String, Number],
			required: true,
		},
		orderDetails: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			order: new Order(),
			newAddress: null,
			submitLoading: false,
		};
	},
	methods: {
		updateShippingAddress(addressId) {
			this.newAddress = this.numericOrDefault(addressId);
		},
		numericOrDefault(value) {
			const numericValue = Number(value);
			return Number.isNaN(numericValue) ? 0 : numericValue;
		},
		submit() {
			if (this.newAddress) {
				this.submitLoading = true;
				this.order.changeShippingAddress(this.orderId, { address_id: this.newAddress }).then(() => {
					this.$emit('changed');
					this.$bvModal.hide('change-address-modal');
				}).catch((error) => {
					console.log(error);
				}).finally(() => {
					this.submitLoading = false;
				});
			}
		},
	},
};
</script>

<style scoped>

</style>
