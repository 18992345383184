<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="animated fadeIn">
		<template v-if="!loading && hasData">
			<b-card class="mb-3">
				<b-row style="row-gap: 5px">
					<b-col class="col-12 col-md-4">
						<p class="mb-0">
							<b>{{ translate('order_date') }}</b>: {{ $moment(data.attributes.order_info.date_without_format.date).format(dateFormat) }}
						</p>
					</b-col>
					<b-col class="col-12 col-md-4">
						<p class="mb-0">
							<b>{{ translate('order_paid_date') }}</b>: {{ data.attributes.order_info.paid_at ? $moment(data.attributes.order_info.paid_at.date).format(dateFormat) : '-' }}
						</p>
					</b-col>
					<b-col class="col-12 col-md-4">
						<p class="mb-0">
							<template v-if="data.attributes.order_info.user.type === distributor">
								<b>{{ translate('distributor_order_id') }}</b>: {{ data.attributes.order_info.order_id }}
							</template>
							<template v-else>
								<b>{{ translate('customer_order_id') }}</b>: {{ data.attributes.order_info.order_id }}
							</template>
						</p>
					</b-col>
					<b-col class="col-12 col-md-4">
						<p class="mb-0">
							<b>{{ translate('payment') }}</b>: <span v-if="data.attributes.order_info.wallet_split">{{ translate('cashondelivery') }} + </span> {{ data.attributes.order_info.payment_method }}
						</p>
					</b-col>
					<b-col
						v-if="data.attributes.user_info"
						class="col-12 col-md-4">
						<p class="mb-0">
							<b>{{ translate('user') }}</b>: {{ data.attributes.user_info }}
						</p>
					</b-col>
					<b-col
						v-if="data.attributes.order_info.shipping_method_code_name"
						class="col-12 col-md-4">
						<p class="mb-0">
							<b>{{ translate('shipping_method') }}</b>: {{ shippingMethod }}
						</p>
					</b-col>
					<b-col
						v-if="data.attributes.tracking.length !== 0"
						class="col-12 col-md-4">
						<b>{{ translate('tracking_number') }}</b>:
						<template
							v-if="data.attributes.tracking[Object.keys(data.attributes.tracking)[0]]['tracking_url']">
							<a
								:href="data.attributes.tracking[Object.keys(data.attributes.tracking)[0]]['tracking_url']"
								target="_blank">
								{{ data.attributes.tracking[Object.keys(data.attributes.tracking)[0]]['tracking_number'] }}
							</a>
						</template>
						<template v-else>
							{{ data.attributes.tracking[Object.keys(data.attributes.tracking)[0]]['tracking_number'] }}
						</template>
					</b-col>
					<b-col
						v-if="data.attributes.order_info.shipped_date"
						class="col-12 col-md-4">
						<b>{{ translate('shipped_date') }}</b>:
						{{ $moment(data.attributes.order_info.shipped_date.date).format(dateFormat) }}
					</b-col>
					<b-col
						v-if="data.attributes.order_info.picked_up_date"
						class="col-12 col-md-4">
						<b>{{ translate('picked_up_date') }}</b>:
						{{ $moment(data.attributes.order_info.picked_up_date.date).format(dateFormat) }}
					</b-col>
				</b-row>
				<hr>
				<b-row style="row-gap: 5px">
					<b-col
						v-if="data.attributes.order_info.status !== 'split'"
						class="col-12 col-md-4">
						<b>{{ translate('order_status') }}</b>: {{ translate(data.attributes.order_info.status) }}
					</b-col>
					<b-col class="col-12 col-md-4">
						<b>{{ translate('order_type') }}</b>: {{ translate(data.attributes.order_type.replace('-', '_')) }}
					</b-col>
					<b-col class="col-12 col-md-4">
						<b>{{ translate('total_bvs') }}</b>: {{ data.attributes.total_bvs }}
					</b-col>
					<b-col
						v-if="data.attributes.tracking_status !== ''"
						class="col-12 col-md-4">
						<b>{{ translate('tracking_status') }}</b>: {{ translate(data.attributes.tracking_status) }}
					</b-col>
				</b-row>
				<template
					v-if="isAdmin && data.attributes.sponsor_info.type === distributor">
					<hr>
					<b-row style="row-gap: 5px">
						<b-col class="col-12 col-md-4">
							<b>{{ translate('sponsor') }}</b>: <span
								class="btn-link exchange-text pointer"
								@click="toDashboard({ distributorId: data.attributes.sponsor_info.id.toString() })">
								{{ data.attributes.sponsor_info.id }} - {{ data.attributes.sponsor_info.username }}
							</span> {{ data.attributes.sponsor_info.name }}
						</b-col>
					</b-row>
				</template>
			</b-card>
			<b-row v-if="(Object.keys(data.attributes.billing).length > 0 && !data.attributes.hide_billing) || Object.keys(data.attributes.shipping).length > 0">
				<b-col>
					<div class="table-responsive">
						<table class="table table-bordered mb-0 table-hover table-striped">
							<thead>
								<tr>
									<th
										v-if="showShippingAddress"
										style="vertical-align: middle">
										{{ translate('shipping_address') }}
										<b-button
											v-if="allowToChangeAddress"
											v-b-modal.change-address-modal
											class="btn-sm ml-2">
											{{ translate('change_shipping_address') }}
										</b-button>
									</th>
									<th
										v-if="Object.keys(data.attributes.billing).length > 0"
										style="vertical-align: middle">
										{{ translate('billing_address') }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr style="vertical-align: top">
									<td
										v-if="showShippingAddress"
										style="width: 50%">
										<p class="mb-1">
											<b>{{ data.attributes.shipping.name }}</b>
										</p>
										<p
											v-if="data.attributes.shipping.telephone"
											class="mb-1">
											{{ data.attributes.shipping.telephone }}
										</p>
										<p
											v-if="data.attributes.id_number"
											class="mb-1">
											{{ data.attributes.id_number }}
										</p>
										<p class="mb-1 text-wrap">
											{{ data.attributes.shipping.line1 }}
										</p>
										<p class="mb-1 text-wrap">
											{{ data.attributes.shipping.line2 }}
										</p>
										<p class="mb-1">
											{{ data.attributes.shipping.line3 }}
										</p>
										<p class="mb-1">
											{{ data.attributes.shipping.line4 }}
										</p>
										<p class="mb-1">
											{{ data.attributes.shipping.line5 }}
										</p>
										<template v-if="showDocumentStatus">
											<b-row>
												<b-col
													sm="12"
													md="9">
													<p>{{ translate('document_status') }} : <strong>{{ translate(`verification_status_${data.attributes.verification_status}`) }}</strong></p>
												</b-col>
												<b-col
													sm="12"
													md="3">
													<b-button
														variant="primary"
														size="sm"
														class="btn-block"
														@click="showDocumentModal = true">
														{{ translate(data.attributes.verification_status === 'valid' ? 'view' : 'update') }}
													</b-button>
												</b-col>
											</b-row>
										</template>
									</td>
									<td
										v-if="Object.keys(data.attributes.billing).length > 0"
										style="width: 50%">
										<p class="mb-1">
											<b>{{ data.attributes.billing.name }}</b>
										</p>
										<p class="mb-1 text-wrap">
											{{ data.attributes.billing.line1 }}
										</p>
										<p class="mb-1 text-wrap">
											{{ data.attributes.billing.line2 }}
										</p>
										<p class="mb-1">
											{{ data.attributes.billing.line3 }}
										</p>
										<p class="mb-1">
											{{ data.attributes.billing.line4 }}
										</p>
										<p class="mb-1">
											{{ data.attributes.billing.line5 }}
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<div class="table-responsive">
						<table class="table mb-0 table-bordered table-hover table-striped">
							<thead>
								<tr>
									<th>
										{{ translate('item') }}
									</th>
									<th class="text-center">
										{{ translate('qty') }}
									</th>
									<th class="text-center">
										{{ translate('unit_price') }}
									</th>
									<th class="text-center">
										{{ translate('total_price') }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in data.attributes.products"
									:key="`${index}_products`">
									<td class="align-middle">
										<p class="mb-0">
											{{ item.item }}
											<template v-if="item.sub_order_id && data.attributes.sub_orders[item.sub_order_id].status === 'backorder'">
												<i
													v-b-tooltip
													:title="translate('is_in_backorder')"
													class="fas fa-exclamation-triangle text-warning text-small pointer"
													@click="goToSubOrder(item.sub_order_id)" />
											</template>
										</p>
									</td>
									<td class="align-middle text-center">
										<p class="mb-0">
											{{ item.qty }}
										</p>
									</td>
									<td class="align-middle text-right">
										<p class="mb-0">
											{{ item.price }}
										</p>
									</td>
									<td class="align-middle text-right">
										<p class="mb-0">
											{{ item.sub_total }}
										</p>
									</td>
								</tr>
								<tr
									v-for="(item, index) in data.attributes.totals"
									:key="`${index}_totals`">
									<td
										colspan="3"
										class="text-right">
										{{ item.code_name === 'shipping' ? translate('shipping_and_handling') : translate(item.code_name) }}
									</td>
									<td class="text-right">
										{{ item.format_value }}
									</td>
								</tr>
								<tr>
									<td
										colspan="3"
										class="text-right">
										<b>{{ translate('total') }}</b>
									</td>
									<td class="text-button text-right">
										<b>{{ data.attributes.order_info.total }}</b>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</b-col>
			</b-row>
			<div v-if="isParentMultiPayment">
				<b-alert
					show
					variant="info"
					v-html="translate('admin_fee_does_not_included', { fee: adminFee })" />
			</div>
			<div v-if="data.attributes.promotions.length > 0">
				<h4 class="">
					{{ translate('zlem_promo_title') }}
				</h4>
				<p>{{ data.attributes.promotions[0] }}</p>
			</div>
			<update-order-document-modal
				v-if="showDocumentStatus && showDocumentModal"
				:order-id="data.id"
				:id-number="data.attributes.id_number"
				:first-name="data.attributes.shipping.first_name"
				:last-name="data.attributes.shipping.last_name"
				:country-code="data.attributes.country_code"
				:verification-status="data.attributes.verification_status"
				@close="showDocumentModal = false"
				@reload="$emit('updated')" />
		</template>
		<is-loading
			:loading-label="translate('loading')"
			:loading="loading"
			:has-data="hasData" />
		<change-shipping-address-modal
			v-if="allowToChangeAddress"
			:user-id="data.attributes.order_info.user.id"
			:order-id="data.attributes.order_info.order_id"
			:order-details="data.attributes"
			@changed="$emit('updated')" />
	</div>
</template>
<script>
import DashboardRedirect from '@/mixins/DashboardRedirect';
import OrderVerification from '@/mixins/OrderVerification';
import WindowSizes from '@/mixins/WindowSizes';
import Order from '@/util/Order';
import {
	OrdersDetails, Products, PurchasePayment, PurchaseShipping, Cart, TrackingStatus, Grids,
} from '@/translations';
import { YMD_FORMAT } from '@/settings/Dates';
import { ORDER_STATUSES as statuses, ORDER_STATUSES } from '@/settings/Statuses';
import { SHIPPING_METHOD_CODES } from '@/settings/Shipping';
import { admin, distributor } from '@/settings/Roles';
import UpdateOrderDocumentModal from './UpdateOrderDocumentModal';
import ChangeShippingAddressModal from '@/components/ChangeShippingAddressModal';

export default {
	name: 'MainOrderDetail',
	messages: [OrdersDetails, Products, PurchasePayment, PurchaseShipping, Cart, TrackingStatus, Grids],
	components: { UpdateOrderDocumentModal, ChangeShippingAddressModal },
	mixins: [DashboardRedirect, OrderVerification, WindowSizes],
	props: {
		data: {
			type: Object,
			required: true,
		},
		loading: {
			type: Boolean,
			required: true,
		},
		hasData: {
			type: Boolean,
			required: true,
		},
		redirect: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			dateFormat: YMD_FORMAT,
			admin,
			distributor,
			statuses: ORDER_STATUSES,
			orderComplete: new Order(),
			orderFraud: new Order(),
			orderVerify: new Order(),
			alert: new this.$Alert(),
			showDocumentModal: false,
		};
	},
	computed: {
		showShippingAddress() {
			// Hide shipping address if the order is a parent order
			if (this.data.attributes.order_info.status === 'split') {
				return false;
			}
			return Object.keys(this.data.attributes.shipping).length > 0 && !this.isPickupOrder;
		},
		allowToChangeAddress() {
			return this.data && this.data.attributes && this.data.attributes.can_change_address;
		},
		showDocumentStatus() {
			return this.data.attributes.verification_status === 'valid'
				|| this.data.attributes.order_info.status === 'document_review'
				|| Object.values(this.data.attributes.sub_orders).some((item) => item.status === 'document_review');
		},
		isAdmin() {
			return this.admin.includes(this.$user.details().type);
		},
		shippingCarrier() {
			try {
				const { shipping_method_code_name: shippingMethod } = this.data.attributes.order_info;
				return SHIPPING_METHOD_CODES[shippingMethod].carrier;
			} catch (error) {
				return null;
			}
		},
		showMarkAsFraudButton() {
			try {
				const { status } = this.data.attributes.order_info;
				return status !== statuses.pending && !this.data.attributes.is_fraud;
			} catch (error) {
				return false;
			}
		},
		showApprovalButtons() {
			try {
				const { status } = this.data.attributes.order_info;
				return status === ORDER_STATUSES.paymentReview;
			} catch (error) {
				return false;
			}
		},
		isPickupOrder() {
			return this.data.attributes.is_pickup_order;
		},
		pickupAtOffice() {
			return this.data.attributes.pickup_at_office;
		},
		shippingMethod() {
			if (this.isPickupOrder || this.pickupAtOffice) {
				return this.translate('pickup_title');
			}

			return (this.shippingCarrier ? `${this.shippingCarrier.toUpperCase()} - ` : '') + this.translate(this.data.attributes.order_info.shipping_method_code_name);
		},
		isParentMultiPayment() {
			try {
				const { is_multi_payment: isMultiPayment, is_parent_order: isParentOrder } = this.data.attributes.multi_payment_info;
				return isMultiPayment && isParentOrder;
			} catch (error) {
				return false;
			}
		},
		adminFee() {
			try {
				return this.data.attributes.multi_payment_info.admin_fee;
			} catch (error) {
				return 0;
			}
		},
	},
	methods: {
		goToSubOrder(subOrderId) {
			this.$emit('go-to-sub-order', subOrderId);
		},
	},
};
</script>
