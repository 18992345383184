import { render, staticRenderFns } from "./UpdateOrderDocumentModal.vue?vue&type=template&id=3616308e&scoped=true&"
import script from "./UpdateOrderDocumentModal.vue?vue&type=script&lang=js&"
export * from "./UpdateOrderDocumentModal.vue?vue&type=script&lang=js&"
import style0 from "./UpdateOrderDocumentModal.vue?vue&type=style&index=0&id=3616308e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3616308e",
  null
  
)

export default component.exports