<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="animated fadeIn">
		<template v-if="!loading && hasData">
			<b-card class="mb-3">
				<b-row style="row-gap: 5px">
					<b-col class="col-12 col-md-4">
						<b>{{ translate('order_status') }}</b>: {{ translate(subOrder.status) }}
					</b-col>
					<b-col
						v-if="subOrder.shipped_date"
						class="col-12 col-md-4">
						<b>{{ translate('shipped_date') }}</b>:
						{{ $moment(subOrder.shipped_date.date).format(dateFormat) }}
					</b-col>
					<b-col
						v-if="subOrder.picked_up_date"
						class="col-12 col-md-4">
						<b>{{ translate('picked_up_date') }}</b>:
						{{ $moment(subOrder.picked_up_date.date).format(dateFormat) }}
					</b-col>
					<b-col
						v-if="subOrder.shipping_method_code_name"
						class="col-12 col-md-4">
						<p class="mb-0">
							<b>{{ translate('shipping_method') }}</b>: {{ shippingMethod }}
						</p>
					</b-col>
					<b-col
						v-if="subOrder.tracking.length !== 0"
						class="col-12 col-md-4">
						<b>{{ translate('tracking_number') }}</b>:
						<template
							v-if="subOrder.tracking[Object.keys(subOrder.tracking)[0]]['tracking_url']">
							<a
								:href="subOrder.tracking[Object.keys(subOrder.tracking)[0]]['tracking_url']"
								target="_blank">
								{{ subOrder.tracking[Object.keys(subOrder.tracking)[0]]['tracking_number'] }}
							</a>
						</template>
						<template v-else>
							{{ subOrder.tracking[Object.keys(subOrder.tracking)[0]]['tracking_number'] }}
						</template>
					</b-col>
					<b-col
						v-if="subOrder.tracking_status !== ''"
						class="col-12 col-md-4">
						<b>{{ translate('tracking_status') }}</b>: {{ translate(subOrder.tracking_status) }}
					</b-col>
				</b-row>
			</b-card>
			<b-row v-if="showShippingAddress">
				<b-col>
					<div class="table-responsive">
						<table class="table table-bordered mb-0 table-hover table-striped">
							<thead>
								<tr>
									<th
										v-if="showShippingAddress"
										style="vertical-align: middle">
										{{ translate('shipping_address') }}
										<b-button
											v-if="allowToChangeAddress"
											v-b-modal.change-address-modal
											class="btn-sm ml-2">
											{{ translate('change_shipping_address') }}
										</b-button>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr style="vertical-align: top">
									<td
										v-if="showShippingAddress"
										style="width: 50%">
										<p class="mb-1">
											<b>{{ subOrder.shipping.name }}</b>
										</p>
										<p
											v-if="subOrder.shipping.telephone"
											class="mb-1">
											{{ subOrder.shipping.telephone }}
										</p>
										<p
											v-if="subOrder.recipient_id_number"
											class="mb-1">
											{{ subOrder.recipient_id_number }}
										</p>
										<p class="mb-1 text-wrap">
											{{ subOrder.shipping.line1 }}
										</p>
										<p class="mb-1 text-wrap">
											{{ subOrder.shipping.line2 }}
										</p>
										<p class="mb-1">
											{{ subOrder.shipping.line3 }}
										</p>
										<p class="mb-1">
											{{ subOrder.shipping.line4 }}
										</p>
										<p class="mb-1">
											{{ subOrder.shipping.line5 }}
										</p>
										<template v-if="showDocumentStatus">
											<b-row class="m-0">
												<b-col
													sm="12"
													md="9"
													class="p-0">
													<p>{{ translate('document_status') }} : <strong>{{ translate(`verification_status_${subOrder.verification_status}`) }}</strong></p>
												</b-col>
												<b-col
													sm="12"
													md="3">
													<b-button
														variant="primary"
														size="sm"
														class="btn-block"
														@click="showDocumentModal = true">
														{{ translate(subOrder.verification_status === 'valid' ? 'view' : 'update') }}
													</b-button>
												</b-col>
											</b-row>
										</template>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<div class="table-responsive">
						<table class="table mb-0 table-bordered table-hover table-striped">
							<thead>
								<tr>
									<th>
										{{ translate('item') }}
									</th>
									<th class="text-center">
										{{ translate('qty') }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in products"
									:key="`${index}_products`">
									<td class="align-middle">
										<p class="mb-0">
											{{ item.item }}
										</p>
									</td>
									<td class="align-middle text-center">
										<p class="mb-0">
											{{ item.qty }}
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</b-col>
			</b-row>
			<update-order-document-modal
				v-if="showDocumentStatus && showDocumentModal"
				:order-id="subOrder.order_id"
				:id-number="subOrder.recipient_id_number"
				:first-name="subOrder.shipping.first_name"
				:last-name="subOrder.shipping.last_name"
				:country-code="subOrder.country_code"
				:verification-status="subOrder.verification_status"
				@close="showDocumentModal = false"
				@reload="$emit('updated')" />
		</template>
		<is-loading
			:loading-label="translate('loading')"
			:loading="loading"
			:has-data="hasData" />
		<change-shipping-address-modal
			v-if="allowToChangeAddress"
			:user-id="data.attributes.order_info.user.id"
			:order-id="subOrder.order_id"
			:order-details="subOrder"
			@changed="$emit('updated')" />
	</div>
</template>
<script>
import DashboardRedirect from '@/mixins/DashboardRedirect';
import OrderVerification from '@/mixins/OrderVerification';
import WindowSizes from '@/mixins/WindowSizes';
import {
	OrdersDetails, Products, PurchasePayment, PurchaseShipping, Cart, TrackingStatus, Grids,
} from '@/translations';
import { YMD_FORMAT } from '@/settings/Dates';
import { SHIPPING_METHOD_CODES } from '@/settings/Shipping';
import { admin } from '@/settings/Roles';
import UpdateOrderDocumentModal from './UpdateOrderDocumentModal';
import ChangeShippingAddressModal from '@/components/ChangeShippingAddressModal';

export default {
	name: 'SubOrderDetail',
	messages: [OrdersDetails, Products, PurchasePayment, PurchaseShipping, Cart, TrackingStatus, Grids],
	components: { UpdateOrderDocumentModal, ChangeShippingAddressModal },
	mixins: [DashboardRedirect, OrderVerification, WindowSizes],
	props: {
		data: {
			type: Object,
			required: true,
		},
		loading: {
			type: Boolean,
			required: true,
		},
		hasData: {
			type: Boolean,
			required: true,
		},
		redirect: {
			type: Boolean,
			required: true,
		},
		subOrderId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			dateFormat: YMD_FORMAT,
			admin,
			showDocumentModal: false,
		};
	},
	computed: {
		subOrder() {
			try {
				return this.data.attributes.sub_orders[this.subOrderId];
			} catch (error) {
				return null;
			}
		},
		products() {
			return this.data.attributes.products.filter((product) => Number(product.sub_order_id) === Number(this.subOrderId));
		},
		showShippingAddress() {
			return Object.keys(this.subOrder.shipping).length > 0;
		},
		allowToChangeAddress() {
			return this.data && this.subOrder && this.subOrder.can_change_address;
		},
		showDocumentStatus() {
			return this.subOrder.verification_status !== null || this.subOrder.status === 'document_review';
		},
		isAdmin() {
			return this.admin.includes(this.$user.details().type);
		},
		shippingCarrier() {
			try {
				const { shipping_method_code_name: shippingMethod } = this.subOrder;
				return SHIPPING_METHOD_CODES[shippingMethod].carrier;
			} catch (error) {
				return null;
			}
		},
		pickupAtOffice() {
			return this.subOrder.pickup_at_office;
		},
		shippingMethod() {
			if (this.pickupAtOffice) {
				return this.translate('pickup_title');
			}

			return (this.shippingCarrier ? `${this.shippingCarrier.toUpperCase()} - ` : '') + this.translate(this.subOrder.shipping_method_code_name);
		},
	},
};
</script>
