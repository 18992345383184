<template>
	<div>
		<div v-if="!loading">
			<orders-details-template
				:loading="loading"
				:data="data"
				:redirect="redirect"
				:has-data="hasData"
				@updated="loadOrder"
				@current-tab="handleTabChange" />
			<hr class="my-4">
			<div id="multiPaymentPlanSection">
				<multi-payments-index
					v-if="isMultiPayment && !isAdminOrCorporate"
					:order-detail-view="true" />
			</div>
			<orders-refunds-index
				v-if="showRefunds && ((hasData && !isMultiPayment) || isAdminOrCorporate)"
				:loading="refundLoading"
				:data="refundData"
				:has-data="refundHasData"
				:pagination="refundPagination"
				:is-refundable="isRefundable"
				@on-change="getRefunds"
				@download-refund="download" />
		</div>
		<is-loading
			v-if="loading"
			:loading-label="translate('loading')"
			:loading="loading" />
	</div>
</template>
<script>
import { YMD_FORMAT } from '@/settings/Dates';
import Order from '@/util/Order';
import {
	OrdersDetails,
} from '@/translations';
import OrdersDetailsTemplate from './components/OrdersDetailsTemplate';
import OrdersRefundsIndex from './components/OrdersRefundsIndex';
import MultiPaymentsIndex from './MultiPayments';
import { admin } from '@/settings/Roles';

export default {
	name: 'OrdersDetails',
	messages: [OrdersDetails],
	components: {
		OrdersDetailsTemplate,
		OrdersRefundsIndex,
		MultiPaymentsIndex,
	},
	data() {
		return {
			orders: new Order(),
			orderRefunds: new Order(),
			downloadRefund: new Order(),
			dateFormat: YMD_FORMAT,
			redirect: false,
			alert: new this.$Alert(),
			admin,
			showRefunds: true,
		};
	},
	computed: {
		loading() {
			return !!this.orders.data.loading;
		},
		data() {
			try {
				const { data } = this.orders.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		hasData() {
			const response = Object.keys(this.data).length;
			return !!response;
		},
		refundLoading() {
			return !!this.orderRefunds.data.loading;
		},
		refundData() {
			try {
				const { data } = this.orderRefunds.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		refundPagination() {
			return this.orderRefunds.data.pagination;
		},
		refundHasData() {
			const response = Object.keys(this.refundData).length;
			return !!response;
		},
		isRefundable() {
			try {
				return this.data.attributes.is_refundable;
			} catch (error) {
				return false;
			}
		},
		isMultiPayment() {
			try {
				return this.data.attributes.multi_payment_info.is_multi_payment;
			} catch (error) {
				return false;
			}
		},
		isAdminOrCorporate() {
			return this.admin.includes(this.$user.details().type);
		},
	},
	mounted() {
		this.loadOrder();
		this.getRefunds();
	},
	methods: {
		download(refundId, orderId) {
			const config = {
				allowEscapeKey: false,
				allowOutsideClick: false,
			};

			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), config);
			this.downloadRefund.downloadRefund(refundId).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]), { type: response.headers['content-type'] });
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.translate('refund_order_file')} ${orderId}.pdf`);
				this.$el.appendChild(link);
				link.click();
				this.alert.close();
			}).catch(() => {
				this.alert.toast('error', this.translate('default_error_message'));
			});
		},
		loadOrder() {
			this.orders.getDetails(this.$route.params.order_id);
		},
		getRefunds(options) {
			this.orderRefunds.getRefunds(this.$route.params.order_id, options);
		},
		handleTabChange(tab) {
			// Refunds' table is visible only for the Detail tab
			this.showRefunds = tab === 0;
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (from.name !== null) {
				if (!from.name.includes('Order') && to.query.option !== 'multi_payments') vm.redirect = true;
			}
		});
	},
};
</script>
