<template>
	<div class="row mb-2 no-gutters">
		<div
			v-if="!addressLoading"
			class="col">
			<div
				class="px-0 pt-3 pb-1">
				<div
					class="row px-3">
					<div class="col-12">
						<address-radio-button
							class="mw-100 address-radio-button"
							:address-data="addressData"
							:address-loading="addressLoading"
							:form-import="form"
							:hide-new-address="addAddress"
							:display-add-new-address="false"
							:borderless="true"
							:show-gift-addresses="!!isGiftOrder"
							:show-normal-addresses="showNormalAddresses"
							:show-pickup-addresses="showPickupAddresses"
							@add-address="addressFormState" />
						<div
							v-if="!addAddress"
							class="row">
							<div class="col-12">
								<hr class="mt-0">
							</div>
						</div>
						<div
							v-if="addAddress">
							<b-row>
								<b-col
									v-if="!isUserAssociated && !addressLoading"
									class="mt-2"
									lg="6"
									cols="12">
									<div class="form-group">
										<label for="country_code">
											{{ translate('country') }}
										</label>
										<select
											id="country_code"
											v-model="selectedCountry"
											name="country_code"
											class="form-control">
											<option value="">
												{{ translate('select_country') }}
											</option>
											<option
												v-for="country in countries"
												:key="country.id"
												:value="country.attributes.code">
												{{ translate(country.attributes.code.toLowerCase()) }}
											</option>
										</select>
									</div>
								</b-col>
							</b-row>
							<address-country-form
								:address="ADDRESSES.shipping"
								:user-country="isUserAssociated"
								:form-import="form"
								:errors-import="addressErrors.errors"
								:country-code-import="isUserAssociated ? countryToShow : selectedCountry"
								:prefix-errors="''"
								:display-cancel-button="false"
								:display-submit-button="false"
								:display-goback-button="false"
								:allow-change-country="false"
								:hide-country-field="!isUserAssociated"
								:display-override-toggle="showOverrideValidation"
								@submitDisabled="newAddressSubmitDisabled = $event" />
						</div>
					</div>
				</div>
				<div
					class="row px-2">
					<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mr-auto">
						<button
							v-if="addAddress && hasAddressData"
							:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
							class="btn btn-link b-4 float-left"
							@click="addressFormState">
							{{ translate('see_address_list') }}
						</button>
						<button
							v-else-if="!addAddress && canChangeToShipping"
							:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
							class="btn btn-link b-4 float-left"
							@click="addressFormState">
							{{ translate('use_another_address') }}
						</button>
					</div>
				</div>
				<div
					v-if="false"
					class="row p-2">
					<div
						id="address"
						class="col-12">
						<b-alert
							variant="danger"
							show>
							<div
								v-for="error in validationErrors[`${stepValidation}.shipping_address.address_id`]"
								:key="error">
								<span
									v-text="error" />
								<br>
							</div>
						</b-alert>
					</div>
				</div>
				<div class="row px-2">
					<!-- NEW ADDRESS BUTTONS -->
					<div
						v-if="addAddress"
						:class="['xs', 'sm'].includes(windowWidth) ? 'mt-3' : 'ml-auto'"
						class="col-12 col-md-auto">
						<div class="row no-gutters justify-content-end">
							<div
								v-if="hasAddressData"
								:class="['xs', 'sm'].includes(windowWidth) ? 'mt-1' : 'mr-2'"
								class="col order-2 order-md-1">
								<b-button
									:disabled="createAddressLoading"
									style="min-width: 150px"
									class="w-100"
									variant="light"
									size="lg"
									@click="addressFormState">
									{{ translate('cancel') }}
								</b-button>
							</div>
							<div
								:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
								class="col col-md-6 order-1 order-md-2 mr-2">
								<b-button
									class="w-100"
									style="min-width: 200px"
									:disabled="newAddressSubmitDisabled"
									variant="primary"
									size="lg"
									@click="newAddress(form)">
									{{ translate('save_address_button') }}
								</b-button>
							</div>
						</div>
					</div>
					<div
						v-else
						:class="['xs', 'sm'].includes(windowWidth) ? 'mt-3' : 'ml-auto'"
						class="col-12 col-md-auto">
						<div class="row no-gutters justify-content-end">
							<div
								:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
								class="col col-md-6 order-1 order-md-2 mr-2">
								<b-button
									class="w-100"
									:disabled="submitLoading || submitDisabled"
									style="min-width: 200px"
									variant="primary"
									size="md"
									@click="$emit('submit')">
									<i
										v-if="submitLoading"
										class="fa fa-spinner fa-spin mr-2" />
									{{ translate('use_this_address') }}
								</b-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="col-12 mt-2 fade-in text-center d-flex justify-content-center align-items-center">
			<div class="col-12">
				<div class="h2">
					<i class="fa fa-fw fa-spinner fa-pulse" />
					<h4 class="mt-3">
						{{ translate('loading') }}
					</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import AddressRadioButton from '@/components/AddressRadioButton/index.vue';
import AddressCountryForm from '@/components/AddressCountryForm/index.vue';
import {
	UNPROCESSABLE, SYSTEM_ALERT,
} from '@/settings/Errors';
import WindowSizes from '@/mixins/WindowSizes';
import AddressBook from '@/util/AddressBook';
import { Addresses as AddressMessages, Countries } from '@/translations';

import Addresses from './Addresses';
import CommonMix from '@/mixins/Common';
import { ADDRESSES } from '@/settings/AddressBook';

export default {
	name: 'ShippingAddress',
	messages: [AddressMessages, Countries],
	components: {
		AddressRadioButton,
		AddressCountryForm,
	},
	mixins: [Addresses, CommonMix, WindowSizes],
	props: {
		userId: {
			type: [String, Number],
			default: '',
		},
		isUserAssociated: {
			type: Boolean,
			default: false,
		},
		countryToShow: {
			type: String,
			default: '',
		},
		submitLoading: {
			type: Boolean,
			default: false,
		},
		submitDisabled: {
			type: Boolean,
			default: false,
		},
		isGiftOrder: {
			type: Boolean,
			default: false,
		},
		showNormalAddresses: {
			type: Boolean,
			default: true,
		},
		showPickupAddresses: {
			type: Boolean,
			default: false,
		},
		canChangeToShipping: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			createAddress: new AddressBook(),
			selectedCountry: '',
			form: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
				signature_required: false,
				override_validation: false,
				address_id: 0,
				personal_document_number: '',
				personal_document_front_file: null,
				personal_document_back_file: null,
				disclaimer: 0,
			},
			alert: new this.$Alert(),
			addAddress: false,
			showOverrideValidation: false,
			ADDRESSES,
			addressType: 'shipping',
			newAddressSubmitDisabled: false,
		};
	},
	computed: {
		createAddressLoading() {
			return this.createAddress.data.loading;
		},
		stepValidation() {
			return this.getStepValidationByName('PurchaseShipping');
		},
	},
	watch: {
		hasAddressData() {
			this.showAddAddressIfNonAreAvailable();
		},
		addressLoading() {
			this.showAddAddressIfNonAreAvailable();
		},
		// eslint-disable-next-line func-names
		'form.address_id': function (addressId) {
			this.$emit('change', addressId);
		},
	},
	created() {
		this.initializeValues();
	},
	methods: {
		initializeValues() {
			this.form.country_code = this.country;
		},
		showAddAddressIfNonAreAvailable() {
			if (!this.addressLoading && !this.addAddress && !this.hasAddressData) {
				this.addAddress = true;
			}
		},
		clearAddressForm() {
			this.form.first_name = '';
			this.form.last_name = '';
			this.form.address = '';
			this.form.address2 = '';
			this.form.city = '';
			this.form.postcode = '';
			this.form.country_code = '';
			this.form.region_id = '';
			this.form.override_validation = false;
			this.form.personal_document_number = '';
			this.form.personal_document_front_file = null;
			this.form.personal_document_back_file = null;
			this.showOverrideValidation = false;
		},
		addressFormState() {
			if (!this.addAddress) {
				this.clearAddressForm();
			}
			if (Object.keys(this.addressErrors.errors).length > 0) {
				const errorFields = Object.keys(this.addressErrors.errors);
				errorFields.forEach((field) => {
					delete this.addressErrors.errors[field];
					this.addressErrors.errors = { ...this.addressErrors.errors };
				});
			}
			this.addAddress = !this.addAddress;
		},
		newAddress(form) {
			form.country_code = this.isUserAssociated ? this.countryToShow : this.selectedCountry;
			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), options);

			const payload = { ...form };
			if (!this.showOverrideValidation) {
				payload.override_validation = undefined;
			}

			if (this.isGiftOrder) {
				payload.gift_address = true;
			}

			this.createAddress.saveAddress(this.userId, this.objectToFormData(payload)).then(() => {
				this.alert.toast('success', this.translate('address_created'), { timer: 6000 });

				this.refreshAddressBook(this.userId).then((response) => {
					if (response.length) {
						const lastAddressPos = response.length - 1;
						this.form.address_id = response[lastAddressPos].id;
					}
				}).then(() => {}).finally(() => { this.addAddress = false; });
			}).catch(() => {
				if (UNPROCESSABLE.includes(this.addressErrors.status)) {
					Object.keys(this.addressErrors.errors).forEach((errorKey) => {
						if (errorKey === 'suggestion') {
							this.showSuggestionAlert(form);
						} else if (
							errorKey === 'address'
							&& !!this.form.address.trim().length
							&& this.addressVerificationCountries.includes(this.form.country_code)
						) {
							this.showOverrideValidation = true;
						}
					});
				}
				if (SYSTEM_ALERT.includes(this.addressErrors.status) || this.addressErrors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else {
					this.alert.close();
				}
			});
		},
		showSuggestionAlert(form) {
			this.getSuggestion.getSuggestion(form).then((suggestionResponse) => {
				let address = '';
				const suggestedAddress = suggestionResponse.response.address;

				Object.keys(suggestedAddress).forEach((item, index) => {
					if (suggestedAddress[item] !== '' && item !== 'region_id') {
						address += `${suggestedAddress[item]}`;
						if (index < (Object.keys(suggestedAddress).length) - 2) {
							address += ', ';
						}
					}
				});
				const trans = {
					title: this.translate('invalid_address'),
					text: this.translate('suggestion_address', { address }),
				};
				const options = {
					config: {
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: this.translate('accept_sugestion'),
						cancelButtonText: this.translate('manual_change'),
						reverseButtons: true,
						allowOutsideClick: false,
						allowEscapeKey: false,
					},
				};
				this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
					Object.keys(suggestedAddress).forEach((suggestionItem) => {
						Object.keys(this.form).forEach((formItem) => {
							if (formItem === suggestionItem) {
								this.form[formItem] = suggestedAddress[suggestionItem];
							}
						});
					});
					this.newAddress(this.form);
				}).catch(() => {});
			}).catch(() => {});
		},
	},
};
</script>
<style scoped>
.address-radio-button::v-deep .card-body {
	padding: 0;
}
</style>
